@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

:root {
  --primary-color: #3a86ff;
  --text-color: #333;
  --background-color: #f8f9fa;
  --card-background: #ffffff;
  --border-color: #e0e0e0;
  --hover-color: #2667ff;
  --error-color: #ff4d6d;
  --success-color: #06d6a0;
  --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cta-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  transition: var(--transition);
}

.cta-button {
  background-color: var(--success-color);
  color: white;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: none;
}

.cta-button:hover {
  background-color: #05b589;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.App-header {
  background-color: var(--card-background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
}

.App-link {
  color: var(--primary-color);
  text-decoration: none;
  position: relative;
  transition: var(--transition);
}

.App-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transition: var(--transition);
}

.App-link:hover::after {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .cta-container {
    top: auto;
    bottom: 20px;
    right: 20px;
    left: 20px;
  }

  .cta-button {
    width: 100%;
    max-width: none;
    padding: 16px 28px;
    font-size: 16px;
  }

  .App-header {
    font-size: calc(14px + 2vmin);
    padding: 40px 20px;
  }
}

@media (max-width: 480px) {
  .cta-container {
    bottom: 10px;
    right: 10px;
    left: 10px;
  }

  .cta-button {
    padding: 14px 24px;
    font-size: 14px;
  }

  .App-header {
    font-size: calc(12px + 2vmin);
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.App-header > * {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Accessibility improvements */
.cta-button:focus {
  outline: 3px solid var(--primary-color);
  outline-offset: 2px;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #f0f0f0;
    --background-color: #121212;
    --card-background: #1e1e1e;
    --border-color: #2c2c2c;
  }

  .cta-button {
    background-color: var(--primary-color);
  }

  .cta-button:hover {
    background-color: var(--hover-color);
  }
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--hover-color);
}