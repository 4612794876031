@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
  --text-color: #333;
  --background-color: #f8f9fa;
  --footer-color: #666;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  flex-grow: 1;
}

.home-title {
  color: var(--primary-color);
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.home-button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.license-info {
  margin-top: 50px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.license-info h3 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.license-info ul {
  padding-left: 20px;
  list-style-type: none;
}

.license-info li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}

.license-info li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-weight: bold;
}

footer {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: auto;
}

@media (max-width: 768px) {
  .home-title {
    font-size: 2rem;
  }

  .button-container {
    flex-direction: column;
    align-items: stretch;
  }

  .home-button {
    text-align: center;
  }

  .license-info {
    padding: 20px;
  }
}