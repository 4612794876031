@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

:root {
  --primary-color: #3a86ff;
  --text-color: #333;
  --background-color: #f8f9fa;
  --card-background: #ffffff;
  --border-color: #e0e0e0;
  --hover-color: #2667ff;
  --error-color: #ff4d6d;
  --success-color: #06d6a0;
}

body {
  background-color: var(--background-color);
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

.container {
  max-width: 800px;
  margin: 60px auto;
  padding: 40px;
  background-color: var(--card-background);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: -0.5px;
}

.tab-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
}

.tab {
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
  opacity: 0.6;
}

.tab.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  opacity: 1;
}

.form-section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 24px;
  letter-spacing: -0.5px;
}

.form-group {
  margin-bottom: 24px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-color);
  font-size: 14px;
}

input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: var(--background-color);
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(58, 134, 255, 0.1);
}

textarea {
  height: 120px;
  resize: vertical;
}

.disclaimer {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid var(--border-color);
}

.disclaimer h3 {
  font-size: 18px;
  color: var(--error-color);
  margin-bottom: 12px;
  font-weight: 600;
}

.disclaimer p {
  font-size: 14px;
  color: var(--text-color);
  line-height: 1.6;
}

.signature-canvas {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--background-color);
}

.button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:disabled {
  background-color: var(--border-color);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.generate-button {
  display: block;
  width: 100%;
  margin-top: 24px;
  padding: 16px;
  font-size: 18px;
  background-color: var(--success-color);
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .container {
    padding: 30px;
    margin: 30px auto;
  }

  .title {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  .tab-container {
    flex-wrap: wrap;
  }

  .tab {
    flex: 1 0 33.33%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
  }

  .button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 20px;
    margin: 20px;
  }

  .title {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .tab-container {
    flex-wrap: wrap;
  }

  .tab {
    flex: 1 0 50%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
  }

  .navigation {
    flex-direction: column;
    gap: 16px;
  }

  .button {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
  }

  input[type="text"],
  input[type="number"],
  textarea,
  select {
    font-size: 14px;
    padding: 10px 14px;
  }

  .logo-preview img {
    max-width: 150px;
    max-height: 75px;
  }
}

/* Subtle animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.form-section {
  animation: fadeIn 0.5s ease-out;
}

.button {
  transition: all 0.3s ease;
}

.button:active {
  transform: scale(0.98);
}

/* Accessibility improvements */
.button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Custom checkbox style */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

input[type="checkbox"]:checked::before {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
}

.agree-section {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.agree-section label {
  margin-left: 12px;
  margin-bottom: 0;
}

.logo-preview {
  margin-top: 12px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--background-color);
  display: inline-block;
}

.logo-preview img {
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
}