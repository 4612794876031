@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman&display=swap');

body {
  background-color: #f8f8f8;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
  font-family: 'Times New Roman', Times, serif;
  max-width: 800px;
  padding: 40px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.title {
  font-family: 'Times New Roman', Times, serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #4285f4;
  text-align: center;
}

.tab-container {
  display: flex;
  gap: 2px;
  margin-bottom: 40px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab {
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  font-weight: 500;
  color: #333;
  background-color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
}

.tab-active {
  background-color: #4285f4;
  color: #ffffff;
}

.form-group {
  margin-bottom: 25px;
  width: 100%;
}

.label {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
  display: block;
}

.input-field,
.select-field,
.address-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: all 0.3s ease;
  background: #ffffff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: 'Times New Roman', Times, serif;
}

.input-field:focus,
.select-field:focus,
.address-field:focus {
  border-color: #4285f4;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.2);
}

.input-field::placeholder,
.select-field::placeholder,
.address-field::placeholder {
  color: #999999;
}

.address-field {
  height: 100px;
  resize: vertical;
}

.input-file {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: all 0.3s ease;
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.signature-canvas {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.button {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  background: #4285f4;
  color: white;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.button:hover {
  background: #357ae8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  text-align: center;
}

.download-button {
  margin-top: 20px;
  animation: pulse 1.5s infinite;
}

.download-button:disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}

.agree-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
}

.agree-section input {
  margin-right: 10px;
}

.disclaimer {
  margin-top: 30px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.disclaimer h3 {
  margin-top: 0;
  font-weight: 700;
  color: #d9534f;
  font-family: 'Times New Roman', Times, serif;
}

.disclaimer p {
  color: #333333;
  line-height: 1.6;
}

.signature-section {
  margin-top: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
}

.logo-preview {
  margin-top: 12px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--background-color);
  display: inline-block;
}

.logo-preview img {
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  .container {
    margin: 30px auto;
    padding: 20px;
  }

  .title {
    font-size: 28px;
  }

  .tab-container {
    flex-direction: column;
  }

  .tab {
    width: 100%;
    text-align: center;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .input-field,
  .select-field,
  .address-field,
  .input-file {
    font-size: 14px;
  }

  .button {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
  }

  .navigation {
    flex-direction: column;
    gap: 10px;
  }

  .logo-preview img {
    max-width: 150px;
    max-height: 75px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin: 20px auto;
    padding: 15px;
  }

  .title {
    font-size: 24px;
  }

  .tab {
    padding: 10px 15px;
  }

  .input-field,
  .select-field,
  .address-field,
  .input-file {
    font-size: 12px;
    padding: 10px;
  }

  .button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .disclaimer {
    padding: 15px;
  }

  .logo-preview img {
    max-width: 100px;
    max-height: 50px;
  }
}