.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 60px;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;
    padding: 0 20px;
  }
  
  .title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #4285f4;
  }
  